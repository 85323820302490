import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer.js"
import Header from "../components/header.js"
import { navigate } from "gatsby"

class Privacy  extends React.Component {
  constructor(props){
    super(props)
    this.backToHome = this.backToHome.bind(this)
  }
componentDidMount= () =>{

  
  const html = document.querySelector("html")
  html.style.overflow = "scroll"
  const body = document.querySelector("body")
  body.style.overflow = "scroll"

 
  
  const loadingBar = document.querySelector("#loading-bar")
  loadingBar.classList.add("loading")
  loadingBar.classList.add("white")
  const register = document.querySelector("#register")
  register.classList.remove("hidden")
  const arrow = document.querySelector("#arrow")
  arrow.classList.add("home")
}
backToHome =()=>{
  navigate("/")
}

render(){
    return(
  <Layout>
    <SEO title="Privacy" />
    <div className="flex-column min-100vh " style={{backgroundColor:"rgb(172, 171, 154)"}}>
   
      <Header handleClick={this.backToHome}  text="back to home" logo={"noLoad"} middle={"no-o-p-0"} loaderClass={"no-load"}/>

      <div className="privacy-content-wrapper max-w-630">
        <div >
        <div className="pad-b-48">
          <h2 className="George medium white mar-44">Privacy Policy</h2>
          
            <p className="text-center Acuman-upper-case white weight-400 max-width-350-center">
              Dibcorp Properties P/L ATF Dibcorp Property Trust & MDB Longland
              St P/L ATF MDB Longland St Trust Your rights in relation to
              privacy
            </p>
          </div>
          <div className="border-top">
              <div className="pad-48 ">
                <p className="Acuman-lowercase left white">
                  We understand the importance of protecting the privacy of an individual’s personal information. This policy statement sets out how Dibcorp Properties P/L ATF Dibcorp Property Trust & Dibcorp Investments P/L ATF Dibcorp Investments Trust and its related entities (we/our/us) aim to protect the privacy of your personal information, your rights in relation to your personal information managed by us and the way we collect, hold, use and disclose your personal information. 
                </p>
                <br/>
                <p className="Acuman-lowercase left white">
                In handling your personal information, we are committed to preserving your privacy in accordance with the Privacy Act 1988 (Cth) (Privacy Act) and its 13 Australian Privacy Principles. This policy statement may be updated from time to time.
                </p>
                <p className="Acuman-lowercase left white">
                <br/>
                This policy statement addresses the following:<br/><br/>
                What kinds of personal information do we collect?<br/>
                How do we collect your personal information?<br/>
                Why do we need your personal information?<br/>
                To whom do we disclose your personal information?<br/>
                Overseas disclosures<br/>
                Direct marketing<br/>
                Security of your personal information<br/>
                Can you access and correct the personal information that we hold about you?<br/>
                Right to refuse access or correction<br/>
                Website cookies<br/>
                Privacy complaints<br/>
                How to contact us<br/>
                </p>
              </div>
              <div className="pad-48 ">
                <h4 className="text left mar-b-17 white ">What kinds of personal information do we collect?</h4>
                <br/>
                <p className="Acuman-lowercase left white">
                  Personal information is information or an opinion about an identified, or reasonably identifiable, individual. During the provision of our products and services, we may collect your personal information.
                  Generally, the kinds of personal information we collect include:
                  your contact and identification information such as your name, previous names and change of name documentation, address, previous addresses, telephone number, email address and date of birth;
                  banking, financial, loan and trading information including information about your assets, tax assessments, rental contracts, occupation, employers and previous employers, income, account balances and activities, and transactions with us or third parties;
                  property and title information including information about land values;
                  credit information, being credit related personal information lawfully created and accessible within the Australian credit reporting system including:
                  identification information including viewing, or obtaining copies of, your driver’s licence, Medicare card, passport, birth certificate or extract, citizenship certificate or evidence of permanent residence, marriage certificate or other evidence of your relationship status and ATM cards;
                  consumer credit liability information including:
                  information about existing and previous credit providers and credit accounts;
                  the types of consumer credit provided;
                  the day on which the accounts were entered into, terminated or otherwise ceased to be in force;
                  the terms and conditions of the consumer credit relating to the repayment of the credit or otherwise prescribed by law; and
                  the maximum amount of credit available under the consumer credit;
                  repayment history information, being information about whether you have met your monthlypayment obligations with respect to consumer credit;
                  information about defaults;
                  any other information lawfully obtainable within the Australian credit reporting system; and
                  credit eligibility information which is information about you, including the Information set out above, that we obtain from a credit reporting body together with information we derive from such information based on our own analysis. This may include an internally generated assessment, which reflects your suitability for a loan arrangement.
                  In some circumstances, we may also hold other personal information provided by you or a credit reporting body.
                </p>
              </div>
              <div className="pad-48 ">
                <h4 className="text left mar-b-17 white ">How do we collect your personal information?</h4>
                <br/>
                <p className="Acuman-lowercase left white">
                  Generally, we collect your personal information directly from you, in the course of interaction with you, including but not limited to when you:
                  make enquiries about our products or services;
                  ask to be placed on our mailing lists;
                  complete a form;
                  use any of our various websites;
                  meet with any of our staff;
                  become a client or debtor; or
                  apply for a job with us.
                  There may be occasions when we collect your personal information from other sources such as an information services provider or a publicly maintained record including but not limited to:
                  the Land Titles Office and other property registers;
                  relevant State and Territory Valuers General;
                  real estate agents and conveyancing professionals;
                  ASIC;
                  the Insolvency and Trustee Services of Australia;
                  credit reporting bodies including Veda Advantage Information Services and Solutions Limited (Veda Advantage), Level 15, 100 Arthur Street, North Sydney NSW 2060; and
                  third party providers which collate information which may otherwise be available through a generally available publication. In particular, we collect information from a third party provider, which collates information from real estate agents, property registers and relevant Valuers General, for the purpose of identifying comparable land values.
                  We may also collect personal information about you when conducting market research.
                  Generally, we only collect your personal information from sources other than you if it is unreasonable or impracticable to collect your personal information from you.
                </p>
              </div>
              <div className="pad-48 ">
                <h4 className="text left mar-b-17 white ">Why do we need your personal information?</h4>
                <br/>
                <p className="Acuman-lowercase left white">
                  We collect, hold, use and disclose your personal information where it is reasonably necessary for the purposes of:
                  providing our products and services to you including:
                  building and construction of residential homes;
                  completing land and property sales;
                  assessing your suitability for a loan arrangement;
                  facilitating financial advice and suitable loan arrangements;
                  ascertaining comparable land values;
                  complying with our legal obligations:
                  as a corporate credit representative of an Australian Credit Licence holder;
                  under the Anti-Money Laundering and Counter Terrorism Finance Act 2006; and
                  under the National Consumer Credit Protection Act 2009 including the responsible lending guidelines; and
                  carrying out our business functions including:
                  recruitment processes for possible employment;
                  distributing promotional materials;
                  market research;
                  accounting, billing and other internal administrative purposes;
                  identifying and informing you of products and services that may be of interest to you; and
                  any other legal requirements.
                  Where personal information is used or disclosed, we take reasonable steps to ensure it is relevant to the purpose for which it is to be used or disclosed.
                  You are under no obligation to provide your personal information to us. However, without certain information from you, we may not be able to provide our products and services to you.
                </p>
              </div>
              <div className="pad-48 ">
                <h4 className="text left mar-b-17 white ">To whom do we disclose your personal information?</h4>
                <br/>
                <p className="Acuman-lowercase left white">
                  We disclose your personal information for the purpose for which we collect it. That is, generally, we will only disclose your personal information for a purpose related to the provision, construction or financing of land and homes.
                  This may include disclosing your personal information to third parties engaged by us to perform administrative or other services, lenders or other credit providers, mortgage insurers, conveyancers, our contractors, related bodies corporate, stationery printing houses, mailing houses, professional advisors or people or entities considering acquiring an interest in our business or assets.
                  We also disclose to the credit reporting body, Veda Advantage (the contact details of which are set out in paragraph 2), information about you having applied for credit. This enables us to collect from Veda Advantage credit related personal information about you for the purposes of:
                  We also disclose to the credit reporting body, Veda Advantage (the contact details of which are set out in paragraph 2), information about you having applied for credit. This enables us to collect from Veda Advantage credit related personal information about you for the purposes of:
                  assessing your suitability for a loan; and
                  facilitating financial advice and suitable loan arrangements.
                  This disclosure is always on a confidential basis or otherwise in accordance with law. We may also disclose your personal information with your consent or if disclosure is required or authorised by law.
                </p>
              </div>
              <div className="pad-48 ">
                <h4 className="text left mar-b-17 white ">Overseas disclosures</h4>
                <br/>
                <p className="Acuman-lowercase left white">
                  We do not disclose personal information to overseas recipients. However, in the event we need to do so:
                  we will only do so in order to provide our services and products and manage our business functions; and
                  before disclosing any personal information to an overseas recipient, we will reasonable steps to ensure the overseas recipient complies with the Australian Privacy Principles or is bound by a substantially similar privacy scheme, unless you consent to the overseas disclosure or it is otherwise required or permitted by law.
                </p>
              </div>
              <div className="pad-48 ">
                <h4 className="text left mar-b-17 white ">Direct marketing</h4>
                <br/>
                <p className="Acuman-lowercase left white">
                  We may use and disclose your personal information in order to inform you of our products and services that may be of interest to you. In the event you do not wish to receive such communications, you can opt-out by contacting us via the contact details set out in paragraph 12 or through any opt-out mechanism contained in a marketing communication to you.
                </p>
              </div>
              <div className="pad-48 ">
                <h4 className="text left mar-b-17 white ">Security of your personal information</h4>
                <br/>
                <p className="Acuman-lowercase left white">
                  We take reasonable steps to ensure that the personal information we hold is protected from misuse, interference and loss and from unauthorised access, modification or disclosure. We hold personal information in both hard copy and electronic forms in secure databases on secure premises, accessibleonly by authorised staff.
                  We will destroy or de-identify personal information in circumstances where it is no longer required, unless we are otherwise required or authorised by law to retain the personal information.
                  You should be aware that the Internet is not a secure environment. If you use the Internet to send us information, including your email address, it is sent at your own risk.
                </p>
              </div>
              <div className="pad-48 ">
                <h4 className="text left mar-b-17 white ">Can you access and correct the personal information that we hold about you?</h4>
                <br/>
                <p className="Acuman-lowercase left white">
                  We take reasonable steps to ensure personal information we collect, hold, use and disclose is accurate, up-to-date and complete.
                  Under the Privacy Act, you have a right to access and seek correction of your personal information that is collected and held by us. If at any time you would like to access or seek correction of the personal information that we hold about you, or you would like more information on our approach to privacy,please contact our Privacy Officer via the contact details set out in paragraph 12.
                  We will grant access to your personal information to the extent required or authorised by the Privacy Act or other law and take reasonable steps to correct personal information where necessary and appropriate.
                  To obtain access to your personal information:
                  you will have to provide proof of identity. This is necessary to ensure that personal information is provided only to the correct individuals and that the privacy of others is protected;
                  we request that you be reasonably specific about the information you require; and
                  we may charge you a reasonable administration fee, which reflects the cost to us, for providing access in accordance with your request. In the event we correct personal information that we have previously disclosed to another entity, you may request us to notify that other entity of the correction. In these circumstances, we will take reasonable steps to notify the other entity of the correction unless it would be impracticable or unlawful to do so.
                  We will endeavour to respond to your request to access or correct your personal information within 30 days from your request.
                  You may also seek to access and correct personal information we have obtained about you from Veda Advantage by contacting them on the details set out in paragraph 2.
                </p>
              </div>
              <div className="pad-48 ">
                <h4 className="text left mar-b-17 white ">Right to refuse access or correction</h4>
                <br/>
                <p className="Acuman-lowercase left white">
                  We reserve our right to refuse your request to access or correct your personal information if we consider your request to be frivolous or vexatious or if we are otherwise legally entitled to do so.
                  If we refuse your request to access or correct your personal information, we will provide you with written reasons for the refusal and details of complaint mechanisms. We will nevertheless take reasonable steps to provide you with access in a manner that meets both your, and our, needs.
                  If we refuse your request to correct your personal information that we hold and you provide us with a statement that the information is inaccurate, out-of-date, incomplete, irrelevant or misleading, we will take reasonable steps to associate the statement in such a way that will make the statement apparent to users of the information.
                </p>
              </div>
              <div className="pad-48 ">
                <h4 className="text left mar-b-17 white">Website cookies</h4>
                <br/>
                <p className="Acuman-lowercase left white">
                  We make limited use of ‘cookies’ technology on our website. We use cookies to improve the functionality of our website which may retain information provided to us.
                  Cookies cannot determine the identity of individual users. It is a feature of your Internet browser that you can disable at any time.
                </p>
              </div>
              <div className="pad-48 ">
                <h4 className="text left mar-b-17 white">Privacy complaints</h4>
                <br/>
                <p className="Acuman-lowercase left white">
                  Please direct all privacy complaints to our Privacy Officer.At all times, privacy complaints:
                  will be treated seriously;
                  will be dealt with promptly;
                  will be dealt with in a confidential manner; and
                  will not affect your existing obligations to us or affect the commercial arrangements you have with us.
                  In the event you have a complaint with respect to privacy, the Privacy Officer will commence an investigation and inform you of the outcome of the investigation. If you are dissatisfied with the outcome of the investigation, you may refer the complaint to the Federal Office of the Australian Information Commissioner or, if you would like to participate in an external dispute resolution process, to Credit Ombudsman Services Ltd, Phone 1800 138 422 or PO Box A252, Sydney South NSW 1235.
                </p>
              </div>
              <div className="pad-48 ">
                <h4 className="text left mar-b-17 white">How to contact us</h4>
                <br/>
                <p className="Acuman-lowercase left white">
                  For further information or enquiries regarding your personal information, please contact our PrivacyOfficer at:Email: leesa@dibcorp.com.auAddress: Privacy Officer Dibcorp Properties Pty Ltd 19 Longland Street, NewsteadPh: (07) 32583002Privacy Policy Statement last updated May 2021
                </p>
                <br/>
                <br/>
                <br/>
              </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </Layout>
    )}
}

export default Privacy
